<template>
  <b-modal
    id="modal-from-cargo"
    v-model="modalCargo"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    size="lg"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="lg"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label="* NOMBRE"
              label-for="nombre"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  v-model="form.nombre"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label="DESCRIPCION"
              label-for="descripcion"
              label-cols="12"
              label-cols-lg="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Descripcion"
                rules=""
              >
                <b-form-input
                  id="descripcion"
                  v-model="form.descripcion"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.descripcion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label="* FUNCIONES"
              label-for="funciones"
              label-cols="12"
              label-cols-lg="2"
            >
              <div v-for="(funcion, index) in funciones" :key="index">
                <b-form-group class="mb-0-5">
                  <b-form-checkbox v-model="form.funciones" :value="funcion">
                    {{ funcion.nombre }}
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <!-- <v-select
                v-model="form.funciones"
                multiple
                label="nombre"
                :options="funciones"
              /> -->
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import store from "@/store/index";
import CargoServices from "../services/index";
import funcionServices from "@/modules/configuracion/roles/services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormCheckbox,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        nombre: "",
        descripcion: "",
        funciones: [],
      },
      funciones: [],
      required,
      submitted: false,
      errores: [],
      modalCargo: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR CARGO" : "NUEVO CARGO";
    },
  },
  watch: {
    "form.nombre": function () {
      this.errores.nombre = [];
    },
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      this.obtenerFunciones();
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.nombre = "";
          this.form.descripcion = "";
          this.form.funciones = [];
          this.modalCargo = true;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.nombre = data["nombre"];
          this.form.descripcion = data["descripcion"];
          this.form.funciones = data["funciones"];
          this.modalCargo = true;
          break;
        }
      }
      this.errores = [];
    },
    cerrarModal() {
      this.form.id = null;
      this.modalCargo = false;
      this.limpiar();
    },
    actualizarCargo(item) {
      this.form.id = null;
      this.modalCargo = false;
      this.limpiar();
      this.$emit("actualizarCargo", item);
      this.submitted = false;
    },

    // SERVICES
    async registrar() {
      try {
        const response = await CargoServices.store(this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCargo(response.data.cargo);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        if (err.response.data.errors) {
          this.errores = err.response.data.errors;
        }
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await CargoServices.update(this.form.id, this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCargo(response.data.cargo);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    limpiar() {
      this.form.id = null;
      this.form.nombre = "";
      this.form.descripcion = "";
      this.form.funciones = [];
      this.$refs.simpleRules.reset();
      this.modalCargo = false;
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    async obtenerFunciones() {
      try {
        const response = await funcionServices.get();
        this.funciones = response.data.map((g) => ({
          id: g.id,
          nombre: g.nombre,
          nombre_guard: g.nombre_guard,
        }));
        this.errores = [];
      } catch (err) {
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
</style>